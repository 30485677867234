import { NavLink } from "react-router-dom";
import { FaCode } from "react-icons/fa";
import DevProjects from "./DevProjects";
import { AiOutlineArrowLeft } from "react-icons/ai";

function ReactProjects() {
  return (
    <>
      <p className="p_description">
        <FaCode /> Web apps and projects using <code>Frameworks</code>{" "}
        and <code>Utilities</code> <FaCode />
      </p>
      <div className="img_container">
        <div className="decoration_words">
          <DevProjects
            className="item item_1"
            projectLink="https://classic-todo-app.vercel.app/"
            projectName="#TodoApp"
          />
          <DevProjects
            className="item item_3"
            projectLink="https://test-app-charlybgood.vercel.app/"
            projectName="#User_Cards"
          />
          <DevProjects
            className="item item_2"
            projectLink="https://no-state-node-delta.vercel.app/Welcome"
            projectName="#NoStateNode"
          />
          <DevProjects
            className="item item_1"
            projectLink="https://img-gallery-react.vercel.app/"
            projectName="#imgGallery"
          />
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/DevHome">
          <AiOutlineArrowLeft />
        </NavLink>
      </button>
    </>
  );
}

export default ReactProjects;
