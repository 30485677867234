import React from "react";
import { FaCoffee } from "react-icons/fa";
import "../stylesheets/Footer.css";


function Footer() {
  return (
    <footer className="smedia">
      <p>
        ~{" "}
        <a
          href="https://github.com/CharlyBGood"
          target="_blank"
          rel="noreferrer"
        >
          by Charly BGood
        </a>
        {" "}~
      </p>
      <p>

      </p>
      <a href='https://cafecito.app/charlybgood' rel='noreferrer' target='_blank'>
        <button>
          Buy me a Coffee <FaCoffee />
        </button>
      </a>
    </footer>
  );
}

export default Footer;
